<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="createItem">
          <feather-icon icon="LayersIcon" class="mr-50" />
          <span>duplicate</span>
        </b-dropdown-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import questionsApi from "@/apiServices/questionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["item"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,

      prams: {
        id: "",
      },
    };
  },
  created() {
    // console.log(this.Category);
    // this.Category.image = null;
  },
  methods: {
    createItem() {
      let temp = this.item;
      delete temp._id;
      delete temp.updatedAt;
      delete temp.createdAt;

      let questionTemp = [];
      temp.answers.forEach((element) => {
        questionTemp.push(element._id);
      });
      temp.answers = questionTemp;

      temp.title = temp.title + " duplicate";
      questionsApi.createquestion(temp).then((response) => {
        if (response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "item duplicated",
              icon: "LayersIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showModal() {},
  },
};
</script>
