<template>
  <div>
    <!-- modal trigger button -->

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="showModal"
    >
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span>import Questions</span>
    </b-button>

    <!-- <b-button
        id="show-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        delete
      </b-button> -->

    <!-- modal -->
    <b-modal
      ref="my-modal-import"
      modal-class="modal-info"
      hide-footer
      title="update question"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="questionInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">questions</h5>
              <small class="text-muted"> </small>
            </b-col>
            <b-progress v-model="value" max="100" />
            <b-col md="12">
              <b-form-group label="question Cover Image" label-for="exelFile">
                <validation-provider
                  #default="{ errors }"
                  name="exelFile"
                  rules="required"
                >
                  <b-form-file
                    :state="Boolean(exelFile)"
                    id="exelFile"
                    v-model="exelFile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-card-text class="mb-0">
              Reticulating {{ value + "Question" }}
            </b-card-text>

            <b-progress
              :max="sheetRows.length"
              height="2rem"
              class="w-100"
              v-if="sheetRows.length > 1"
            >
              <b-progress-bar variant="success" :value="value">
                <span
                  >Progress:
                  <strong
                    >{{ value.toFixed(2) }} / {{ sheetRows.length }}</strong
                  ></span
                >
              </b-progress-bar>
            </b-progress>
          </b-row>
        </validation-observer>
      </form>
      <b-row variant="info" class="mt-25">
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-secondary"
            block
            @click="hideModal"
          >
            No
          </b-button>
        </b-col>
        <b-col md="5">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-info"
            block
            @click="importExel"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="mr-50 text-info">import</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BProgress,
  BProgressBar,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import questionsApi from "@/apiServices/questionsApi";
import answersApi from "@/apiServices/answersApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addAnswerSection from "@/views/answers pages/components/add answers section.vue";
import imagesApi from "@/apiServices/imagesApi";
import readXlsxFile from "read-excel-file";

export default {
  components: {
    BProgressBar,
    BProgress,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addAnswerSection,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      value: 0,
      options: ["single_choice", "multi_choice", "true_false"],
      exelFile: null,
      sheetRows: [],
      prams: {
        id: "",
      },
      questions: [],
    };
  },
  created() {
    // console.log(this.question);
    // this.question.imageCover = null;
  },
  methods: {
    async importExel() {
      await readXlsxFile(this.exelFile).then((data) => {
        // console.log(data);
        this.sheetRows = data;
        for (const element of this.sheetRows) {
          if (!this.value == 0 && this.value > 1600) {
            this.addQuestion(element, this.value - 1);
          }
          this.value = this.value + 1;
        }
      });
      // this.importquestions(this.questions);

      // console.log(this.exelFile);
    },
    async importquestions(questionFormData) {
      await questionsApi.importquestions(questionFormData).then((response) => {
        if (response.data) {
          // console.log(response.data);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    async addQuestion(question, index) {
      this.questions.push({
        title: question[0],
        grad: 0,
        answers: [],
        type: question[1],
      });
      let tempAnswers = question.slice(2);
      tempAnswers = tempAnswers.filter((x) => x != null);
      let res = [];
      for (let i = 0; i < tempAnswers.length; i += 2) {
        res.push({ title: tempAnswers[i], isCorrect: tempAnswers[i + 1] });
      }
      tempAnswers = res;
      let interval = 1000;
      for (const element of tempAnswers) {
        // console.log(element);
        this.questions[index].answers.push(element);
      }
      // await this.createquestions(this.questions[index]);
      // this.value = this.value + 1;
    },
    showModal() {
      this.$refs["my-modal-import"].show();
    },
    hideModal() {
      this.$refs["my-modal-import"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
    },
  },
};
</script>
